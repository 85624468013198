.cartTitle{
    padding: 5px 0 ;
    background-color: var(--blackClrOp6);
    margin: 15px 0 20px;
    border-radius: 5px;
    border: 1px dashed var(--blackClrOp1);
}
.cartTitle span{
    background-image: url("/public/assets/cart.svg");
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: right 3px center;
    padding-right: 32px;
}
.emptyCartDiv{
    padding: 50px 0 50px;
    gap: 10px;
}
.mainEmpty{
    height: 80vh;
}
.emptyCartDiv span{
    background-image: url("/public/assets/emptyCart.svg");
    height: 210px;
    width: 210px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 210px;
}
header .emptyCartDiv span{
    height: 150px;
    width: 150px;
    background-size: 150px;
}
.cartSection{
    position: relative;
}
.cartSection .cartTopDiv{
    grid-template-columns: 1fr 1.6fr;
    gap: 20px;
}
.cartSection .manageNumberSection{
    margin: 20px 0 0;
    grid-template-columns: 1fr 40px;
    gap: 40px;
}
/* priceReportDiv */
.priceReportDiv{
    border-radius: 7px;
    overflow: hidden;
    border: 1px solid var(--blackClrOp4);
    margin-bottom: 40px;
    width: 100%;
    font-size: .96em;
}
.priceReportDiv p{
    padding: 14px 15px;
    border-bottom: 1px solid var(--blackClrOp4);
}
.priceReportDiv p:nth-child(even){
    background-color: var(--blackClrOp5);
}
.confirmCartDiv{
    padding: 15px 10px;
}
.confirmCartDiv .submitBtn{
    height: 45px;
}
.confirmCartDiv .submitBtn span{
    background-image: url("/public/assets/whiteCart.svg");
    background-repeat: no-repeat;
    background-position: right 5px top 40%;
    background-size: 17px;
    padding-right: 33px;
}
/* cart */

.backToCart{
    border: 1px dashed var(--blackClrOp1);
    padding: 5px 12px 5px 28px;
    width: fit-content;
    align-self: flex-end;
    border-radius: 7px;
    background-color: var(--blackClrOp6);
    background-repeat: no-repeat;
    background-position: left 7px center;
    background-image: url("/public/assets/leftArrow.svg");
    background-size: 17px;
    transition: background-color .6s;
}
.backToCart:hover{
    background-color: var(--blackClrOp4);
}
.payBtn span{
    background-image: url("/public/assets/creditCard.svg") !important;
}
.insertUserDataTitle{
    background-color: var(--blackClrOp6);
    border-radius: 7px;
    padding: 7px 0;
    border: 1px solid var(--blackClrOp3);
    margin: 10px 0 -10px;
}
/* errorCartDiv */

.errorCartDiv .errorCartText {
    padding-top: 295px;
    background-image: url("/public/assets/noResult.png");
    background-size: 295px;
    background-repeat: no-repeat;
    background-position: top center;
}
.errorCartDiv .tryAgain{
    margin-top: 20px;
    padding: 5px 37px 5px 15px;
    background-color: var(--blackClrOp6);
    width: fit-content;
    border-radius: 5px;
    align-self: center;
    border: 1px dashed var(--blackClrOp2);
    background-image: url("/public/assets/again.svg");
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: right 10px center;
    transition: background-color .6s;
}
.errorCartDiv .tryAgain:hover{
    background-color: var(--blackClrOp4);
}
/* activeTcList */
.activeTcList{
   gap: 10px;
   padding-bottom: 20px;
   border-bottom: 1px solid var(--blackClrOp2);
   margin-bottom: 20px;
}
.activeTcItem{
    grid-template-columns: 80px 1fr auto;
    align-items: center;
    background-color: var(--blackClrOp6);
    padding: 7px;
    border-radius: 7px;
    gap: 10px;
}
.activeTcItem .tcImage{
    border-radius: 7px;
}
.activeTcItem .submitBtn{
    height: 32px;
}
.activeTcItem .confirmBtn{
    background-color: var(--txtSecendaryClrOp4);
    padding: 5px 35px 5px 10px;
    color: var(--txtSecendaryDarkerClr2);
    border-radius: 7px;
    background-image: url("/public/assets/blueCreditCard.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    transition: background-color .6s;
}
.activeTcItem .confirmBtn:hover{
    background-color: var(--txtSecendaryClrOp2);
}
.activeTcItem .cancelItem{
    background-color: var(--tomatoOp4Clr);
    padding: 5px 35px 5px 10px;
    color: var(--redClr);
    border-radius: 7px;
    background-image: url("/public/assets/redClose.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 19px;
    transition: background-color .6s;
}
.activeTcItem .cancelItem:hover{
    background-color: var(--tomatoOp2Clr);
}
@media screen and (min-width:769px) {
    .mainCartDiv{
        display: -ms-grid;
        display: -moz-grid;
        display: grid;
        grid-template-columns: 2fr 1fr;
        width: 100%;
        max-width: unset;
    }
    .priceReportDiv{
        font-size: .98em;
        margin-top: 15px;
        position: sticky;
        top: 80px;
        height: fit-content;
        width: 250px;
        justify-self: flex-end;
    }
    .rightSideDiv .cartItemImage{
        width: 90%;
    }
    .cartTitle{
        display: none;
    }
    .insertUserInfoSection {
        grid-template-columns: 2fr 1fr;
        gap: 15px;
    }
    .insertUserInfoSection .rightSideDiv{
        max-width: 800px;
    }
    .cartDetailsGridSection{
        width: 100%;
        grid-template-columns: 2fr auto;
        justify-content: space-between;
        gap: 20px;
        position: relative;
    }
    .cartDetailsGridSection .rightSideDiv{
        max-width: 650px;
    }
    .activeTcList{
        grid-template-columns: 1fr 1fr;
    }
    .emptyCartListGrid{
        grid-template-columns: 2fr 1fr;
        gap: 20px;
    }
}
@media screen and (min-width:968px) {
    .priceReportDiv{
        width: 300px;
    }
    .activeTcList{
        grid-template-columns: 1fr 1fr 1fr;
    }
}