.customInputDiv{
    position: relative;
}
.customInputDiv input{
    background-color: var(--blackClrOp6);
    height: 40px;
    border-radius: 7px;
    padding: 0 15px;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    transition: margin-top .6s;
    margin-top: 7px;
    direction: ltr ;
    -moz-appearance: textfield;
    appearance: none;
}
.customInputDiv input.faFont:placeholder-shown{
    direction: ltr;
}
.customInputDiv label{
    background-repeat: no-repeat;
    background-size: 18px;
    padding-right: 35px;
    background-position: right 5px center;
}
.labelusername,.labelemail{
    background-image: url("/public/assets/email.svg");
}
.labelpassword,.labelcurrentpass,.labelnewpass{
    background-image: url("/public/assets/lock.svg");
}
.labelname,.labelfirstname,.labellastname{
    background-image: url("/public/assets/person.svg");
}
.inputLabel{
    margin-right: 5px;
    height: 40px;
}
.customInputDiv input:placeholder-shown{
    margin-top: -40px;
}
.customInputDiv input::-webkit-outer-spin-button,
.customInputDiv input::-webkit-inner-spin-button{
    -webkit-appearance: none;
}
.customInputDiv input:placeholder-shown + .changeInputVisi{
    opacity: 0;
    pointer-events: none;
    transition: none !important;
}
.changeInputVisi {
    width: 30px;
    height: 30px;
    background-image: url("/public/assets/visi.svg");
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 5px;
    top: 53px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color .6s;
    border-radius: 7px;
    opacity: 1;
    pointer-events: unset;
    transition: opacity .4s .4s;
}
.invisi{
    background-image: url("/public/assets/invisi.svg");
}
.changeInputVisi:hover {
    background-color: var(--blackClrOp6);
}
.labelphone{
    background-image: url("/public/assets/call.svg");
}
.labeladdress{
    background-image: url("/public/assets/location.svg");
}
.labelpostcode{
    background-image: url("/public/assets/postBox.svg");
}
.labeldescription{
    background-image: url("/public/assets/text.svg");
}
.labeltc{
    background-image: url("/public/assets/shippingGrey.svg");
}
.customInputDiv textarea{
    background-color: var(--blackClrOp6);
    height: 150px;
    border-radius: 7px;
    padding: 15px;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    transition: margin-top .6s;
    margin-top: 7px;
    direction: ltr ;
    resize: none;
}
.customInputDiv textarea.faFont{
    direction: rtl ;
}
.customInputDiv textarea.faFont:placeholder-shown{
    direction: ltr;
}
.customInputDiv textarea:placeholder-shown{
    margin-top: -45px;
}
