.coverSection{
    margin-top: 20px;
    position: relative;
   
}
.coverItemLink{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.conversImageDiv{
    overflow-x: auto;
    scroll-behavior: smooth;
    overflow-y: hidden;
    border-radius: 7px;
    aspect-ratio: 2/1.1;
    width: 100%;
}
.coverItem{
    flex:  0 0 100%;
}
.silderImage{
    aspect-ratio: 2/1.1;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}
.actionDiv{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 15px;
}
.sliderBtn{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--bgClr);
    background-size: 22px;
    background-repeat: no-repeat;
    box-shadow: 0 0 10px var(--txt5Clr);
    opacity: .7;
}
.sliderBtnDiv{
    position: absolute;
    right: 15px;
    bottom: 15px;
}
.preCoverBtn{
    background-image: url("/public/assets/leftArrow.svg");
    background-position: center left 42%;
}
.nextCoverBtn{
    background-image: url("/public/assets/rightArrow.svg");
    background-position: center right 42%;
}
.dotsList{
    gap: 4px;
    position: absolute;
    bottom: 22.5px;
    left: 20px;
    align-items: center;
    width: 50%;
    border-radius: 7px 0 0 7px;
}
.dot{
    width: 10px;
    height: 10px;
    background-color: var(--txtCClr);
    box-shadow: 0 0 3px var(--txt3Clr);
    border-radius: 50%;
    transition: opacity .4s,height .4s,width .4s;
    cursor: pointer;
    opacity: .8;
}
.activeDot{
    width: 12px;
    height: 12px;
    opacity: 1;
    background-color: var(--bgClr);
}
@media screen and (min-width:769px) {
    .silderImage,.conversImageDiv{
        aspect-ratio: 2/.9;
    }
}
@media screen and (min-width:968px) {
    .silderImage,.conversImageDiv{
        aspect-ratio: 2/.7;
    }
}