header{
    position: inherit;
}
.headerbg{
    position: fixed;
    width: 100vw;
    height: 50px;
    top: 0;
    left: 0;
    background-color: var(--bgClr);
    z-index: -1;
}
.row1{
    padding: 10px 0;
    position: sticky;
    top: 0;
    background-color: var(--bgClr);
    z-index: 975;
}
.mobileSearchSection{
    border-bottom: 1px solid var(--blackClrOp3);
    padding-bottom: 10px;
    position: sticky;
    top: 10.5px;
    z-index: 950;
}
.row3{
    background-color: var(--bgClr);
    transform: translateY(-2px);
    position: sticky;
    top: 26px;
    z-index: 974;
    border-bottom: 1px solid var(--blackClrOp3);
    display: none;
    font-size: .9em;
    gap: 5px;
}
.mainCatDiv{
    height: 60vh;
    width: 100%;
    position: absolute;
    right: 0;
    top: 32px;
    padding-top: 1px;
    background-color: var(--bgClr);
    border-radius: 0 0 7px 7px;
    box-shadow: 0 7px 15px var(--blackClrOp1);
    display: none;
    animation: fadein .3s;
    overflow-x: hidden;
    overflow-y: auto;
}
.showMainCat:hover + .mainCatDiv, .mainCatDiv:hover{
    display: block;
}
.mainCatList{
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.catDetailsDiv{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 80%;
    display: none;
    border-right: 1px solid var(--blackClrOp1);
}
.catDetailsDiv > div{
    flex-direction: column;
    box-sizing: border-box;
    padding: 5px;
    flex-wrap: wrap;
}
.catItem{
    width: 20%;
    padding: 7px 32px 7px 5px;
    cursor: pointer;
    border-right: 3px solid transparent;
    background-color: transparent;
    box-sizing: border-box;
    transition: background-color .6s , border-right .6s;
    background-image: url("/public/assets/circle.svg");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 11px;
}
.catItem:hover{
    border-right: 3px solid var(--tomatoClr);
    background-color: var(--tomatoOp4Clr);
}
.catItem:hover + .catDetailsDiv,.catDetailsDiv:hover{
    display: -webkit-flex;
    display: flex;
    background-color: var(--greyClr2);
}
.catDetailsDivGrey{
    background-color: var(--greyClr2);
}
.subLinkItem{
    padding: 5px 15px;
    cursor: pointer;
    padding-right: 35px;
    background-image: url("/public/assets/link.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: right 10px center;
    transition: background-color .6s;
    position: relative;
    width: 225px;
    white-space: nowrap;
}
.subLinkItem:hover{
    background-color: var(--blackClrOp4);
}
.subLinkItem::after{
    content: "";
    background-image: url("/public/assets/leftArrow.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 30px;
    width: 30px;
    opacity: .4;
    transition: opacity .6s;

}
.subLinkItem:hover::after{
    opacity: 1;
}
.row3Item{
    padding: 5px 27px 5px 15px;
    position: relative;
    background-position: right 2px center;
    background-repeat: no-repeat;
    white-space: nowrap;
}
.row3Item::after{
    width: 0;
    height: 2px;
    background-color: var(--tomatoOp1Clr);
    content: "";
    position: absolute;
    right: 0;
    bottom: -1px;
    pointer-events: none;
    z-index: 990;
    transition: width .6s;
}
.row3Item:hover::after{
    width: 100%;
}
.row3Item.offers{
    background-image: url("/public/assets/hot.svg");
    background-size: 14.2px;
}
.row3Item.offer{
    background-image: url("/public/assets/hot.svg");
    background-size: 16px;
}
.row3Item.blog{
    background-image: url("/public/assets/blog.svg");
    background-size: 16px;
}
.row3Item.tracking{
    background-image: url("/public/assets/tracking.svg");
    background-size: 16px;
}
.row3Item.faq{
    background-image: url("/public/assets/faq.svg");
    background-size: 16px;
}
.row3Item.about{
    background-image: url("/public/assets/info.svg");
    background-size: 16px;
}
.showMainCat{
    background-size: 16px;
    background-image: url("/public/assets/menu.svg");
}
.logo{
    font-weight: bolder;
    background: rgb(62,52,227);
    background: linear-gradient(90deg, #7851d3 0%, #2c8ce0 71%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}
.userBtn{
    background-image: url('/public/assets/user.svg');
    background-position: center;
    background-size: 16px;
    background-repeat: no-repeat;
}
.mainUserBtn{
    display: none;
}
.menuBtn{
    background-image: url('/public/assets/menu.svg');
    background-position: center;
    background-size: 16px;
    background-repeat: no-repeat;
}
.cartBtn{
    background-image: url('/public/assets/bag.svg');
    background-position: center;
    background-size: 13px;
    background-repeat: no-repeat;
}
/* mobileSearchDiv */
.mobileSearchdiv,.mainSearchDiv{
    width: 80%;
    max-width: 400px;
    position: relative;
}
.mainSearchDiv{
    display: none;
    max-width: 500px;
}
.mSearchInput,.mainSearchInput{
    height: 35px;
    width: 100%;
    padding: 0 35px 0 60px;
    background-image: url("/public/assets/search.svg");
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: right 8px center;
}
.mobileSearchLink,.mainSearchLink{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    padding-inline: 9px;
    background-color: transparent;
    border-radius: 7px 0 0 7px;
    opacity: 1;
    transition: opacity .3s , background-color .3s;
}
.mobileSearchLink:hover,.mainSearchLink:hover{
    background-color: var(--blackClrOp6);
}
.mSearchInput:placeholder-shown + .mobileSearchLink{
    opacity: 0;
    pointer-events: none;
}
.mainSearchInput:placeholder-shown + .mainSearchLink{
    opacity: 0;
    pointer-events: none;
}
/* navigationBar */
.closeNavBtn{
    background-image: url("/public/assets/close.svg");
    background-size: 19px;
    margin: 10px 0 10px 10px;
    min-height: 32px;
    align-self: flex-end;
}
.navigationCloser{
    background-color: var(--blackClrOp1);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 976;
    opacity: 0;
    transition: opacity .3s;
    pointer-events: none;
}
.navigationCloser.active{
    opacity: 1 !important;
    pointer-events: unset;
}
.navigationBar{
    height: 100vh;
    width: 0;
    background-color: var(--bgClr);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 980;
    transition: width .3s;
    overflow-y: auto;
    white-space: nowrap;
    flex-direction: column;
    overflow-x: hidden;
    padding-bottom: 30px;
}
.navigationBar.active{
    width: 320px !important;
}
.catParentDiv{
    position: relative;
    width: 94%;
    align-self: center;
}
.subCatList::after{
    position: absolute;
    left: 8px;
    top: 12px;
    background-image: url("/public/assets/leftArrow.svg");
    background-position: center;
    content: "";
    width: 18px;
    height: 18px;
    background-size: 16px;
    background-repeat: no-repeat;
    transition: transform .3s;
}
.subCatList{
    height: 0;
    overflow: hidden;
    border-right: 4px solid var(--blackClrOp4);
    background-color: var(--blackClrOp7);
    margin-right: 10px;
}
.catNameInput:checked + .subCatList{
    height: unset;
}
.catNameInput:checked + .subCatList::after{
    transform: rotate(-90deg);
}
.subCatList::after{
    pointer-events: none;
}
.catLabel{
    padding: 7px 35px 7px 10px;
    border-radius: 7px;
    cursor: pointer;
    background-image: url("/public/assets/link.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
}
.subLink{
    padding: 10px 35px 10px 10px;
    background-image: url("/public/assets/link.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    border-radius: 7px;
    transition: background-color .6s;
    position: relative;
}
.subLink:hover{
    background-color: var(--blackClrOp7);
}
.subLink::after{
    content: "";
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("/public/assets/leftArrow.svg");
    background-position: center;
    background-size: 16px;
    background-repeat: no-repeat;
    transition: opacity .6s;
    height: 35px;
    width: 35px;
    opacity: 0;
}
.subLink:hover::after{
    opacity: 1;
}
.staticCat{
    width: 92%;
    margin: 3px auto 0;
    background-repeat: no-repeat;
    background-position: right 7px center;
    padding: 7px 35px 7px 5px;
    border-radius: 7px;
    background-color: transparent;
    transition: background-color .6s;
}
.staticCat:hover{
    background-color: var(--blackClrOp6);
}
.staticCat.home{
    background-image: url("/public/assets/home.svg");
    background-size: 16px;
}
.staticCat.offer{
    background-image: url("/public/assets/hot.svg");
    background-size: 16px;
}
.staticCat.blog{
    background-image: url("/public/assets/blog.svg");
    background-size: 16px;
}
.staticCat.tracking{
    background-image: url("/public/assets/tracking.svg");
    background-size: 16px;
}
.staticCat.faq{
    background-image: url("/public/assets/faq.svg");
    background-size: 16px;
}
.staticCat.about{
    background-image: url("/public/assets/info.svg");
    background-size: 16px;
}
/* headerCartDiv */
.headerCartDiv{
    display: none;
    width: 370px;
    height: 390px;
    border-radius: 7px;
    background-color: var(--bgClr);
    box-shadow: 0 0 10px var(--blackClrOp1);
    position: absolute;
    right: 5px;
    top: 44px;
    z-index: 900;
    grid-template-rows: 38px 1fr 38px;
    gap: 5px;
    padding: 0 15px;
    animation: fadein .4s ;
}
.emptyCartElem{
    height: 100%;
    overflow-y: auto;
    gap: 20px;
}
.emptyCartElem span{
    background-image: url("/public/assets/cart.svg");
    height: 90px;
    width: 90px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90px;
    opacity: .4;
}

/* cartListItem */
.cartNumberNotif{
    position: absolute;
    width: 21px;
    height: 21px;
    right: 35px;
    top: 1px;
    background-color: var(--redClr);
    color: var(--bgClr);
    border-radius: 50%;
}
.cartListItem{
    padding: 10px 0 20px;
    border-bottom: 1px solid var(--blackClrOp2);
}
.cartListItem:last-child{
    border-bottom: none;
    padding-bottom: none;
    padding-bottom: 5px;
    margin-bottom: 10px;
}
.cartDetailsList{
    overflow-y: auto;
    padding-left: 10px;
    border: 1px solid var(--blackClrOp3);
    border-left: none;
    border-right: none;
    padding: 5px 0 0 10px;
}
.cartDetailsList::-webkit-scrollbar {
    width: 8px;
}
.cartDetailsList::-webkit-scrollbar-track {
    background: transparent; 
}
.cartDetailsList::-webkit-scrollbar-thumb {
    background: #ddd; 
    border-radius: 3px;
}
.cartDetailsList::-webkit-scrollbar-thumb:hover {
    background: #aaa; 
}
.cartTopDiv{
    grid-template-columns: 100px 1fr;
    gap: 15px;
    position: relative;
}
.cartItemImage{
    border-radius: 7px;
}
.cartItemPriceDiv .discountPercent{
    padding: 1px 25px 1px 8px;
    background-color: var(--redClr);
    border-radius: 7px;
    color: var(--bgClr);
    background-image: url("/public/assets/percent.svg");
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: right 6px center;
    position: absolute;
    top: -10px;
    right: 0;
}
.realPrice{
    color: var(--greenClr);
}
.offPrice{
    width: 100%;
}
.cartTopDiv .customHr{
    margin: 10px 0;
}

.numberChangedText{
    color: var(--redClr);
    padding-right: 25px;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url("/public/assets/warning.svg");
}
.priceChangedText{
    padding-right: 25px;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: right center;
}
.redText{
    color: var(--redClr);
    background-image: url("/public/assets/warning.svg");
}
.greenText{
    color: var(--greenClr);
    background-image: url("/public/assets/greenWarning.svg");
}
.soldOutList{
    padding-bottom: 20px;
}
.soldOutItem{
    grid-template-columns: 60px 1fr 30px;
    gap: 15px;
    align-items: center;
}
.soldOutImg{
    border-radius: 7px;
    width: 100%;
}
.soldOutItem .removeItem{
    width: 30px;
    height: 30px;
    background-image: url("/public/assets/redTrash.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px;
    background-color: var(--tomatoOp3Clr);
    border-radius: 7px;
    transition: background-color .6s;
}
.soldOutItem .removeItem:hover{
    background-color: var(--tomatoOp2Clr);
}
.soldOutTitle{
    color: var(--redClr);
    background-color: var(--tomatoOp4Clr);
    padding: 7px 0;
    margin-bottom: 15px;
    border-top: 1px solid var(--blackClrOp4);
}
.headerCartDiv .seeCart{
    color: var(--txtSecendaryDarkerClr);
    height: 80%;
    padding: 0 12px 0 25px;
    margin-right: auto;
    background-image: url("/public/assets/leftArrowBlue.svg");
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: left 4px center;
    border-radius: 5px;
    transition: background-color .6s;
}
.headerCartDiv .seeCart:hover{
    background-color: var(--txtSecendaryClrOp2);
}
header .customHr{
    width: 90%;
}
@media screen and (min-width:769px) {
    .mobileSearchSection,.navigationCloser,.navigationBar,.menuBtn{
        display: none !important;
    }
    .mainUserBtn,.mainSearchDiv{
        display: unset;
    }
    .row1{
        border-bottom: 1px solid transparent;
    }
    .row3{
        display: -webkit-flex;
        display: flex;
        top: 30px;
    }
    .cartBtn:hover + .headerCartDiv ,.headerCartDiv:hover{
        display: -ms-grid;
        display: -moz-grid;
        display: grid;
    }
    .cartNumberNotif{
        right: -8px;
    }
    
}
@media screen and (min-width:968px) {
    .row3{
        font-size: .95em;
        gap: 10px;
        top: 29px;
    }
}