/* homeLinks */
.homeLinks{
    overflow-x: auto;
    padding-left: 10px;
}
.homeLinks .linkItem{
    padding: 3px 33px 3px 11px;
    border: 1px solid var(--blackClrOp3);
    background-color: var(--blackClrOp6);
    background-repeat: no-repeat;
    background-position: right 9px center;
    height: fit-content;
    white-space: nowrap;
    border-radius: 15px;
    position: relative;
    transition: background-color .6s;
}
.homeLinks .linkItem:hover{
    background-color: var(--blackClrOp4);
}
.direction{
    font-size: .95em;
    overflow-x: auto;
    gap: 20px;
}
.direction .linkItem{
    background-image: none;
    padding: 5px ;
    position: relative;
    white-space: nowrap;
    color: var(--txt7Clr);
}
.direction .linkItem::after{
    content: "";
    width: 1px;
    height: 18px;
    background-color: var(--blackClrOp2);
    position: absolute;
    top: 7px;
    left: -11px;
    transform: rotate(18deg);
}
.direction > .linkItem:last-child::after{
    display: none;
}