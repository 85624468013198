.formSection{
    max-width: 500px;
}
.formSection{
    margin-top: 30px;
}
.loginTitle{
    text-align: center;
    margin: 20px 0 30px;
}
.errorText{
    color: var(--redClr);
    padding-right: 32px;
    background-size: 18px;
    background-position: right 4px center;
    background-image: url("/public/assets/warning.svg");
    background-repeat: no-repeat;
}
.link{
    background-image: url("/public/assets/link.svg");
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: right 5px center;
    padding-right: 30px;
}
.signForm input[name="name"]:not(:placeholder-shown){
    font-family: "Koodak","Arial" !important;
}