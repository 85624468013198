.searchGridList{
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 20px;
}
.searchGridList .productItem{
    padding: 10px;
    background-color: var(--blackClrOp8);
}
.searchGridList .productItemImage{
    width: 100%;
}
/* fliter */
.showFilterMenuBtn{
    padding: 3px 37px 3px 15px;
    background-image: url("/public/assets/filter.svg");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-color: var(--blackClrOp6);
    border-radius: 5px;
    transition: background-color .6s;
}
.showFilterMenuBtn:hover{
    background-color: var(--blackClrOp4);
}
.filterCloser{
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--txt1Clr);
    height: 100vh;
    width: 100%;
    z-index: 985;
    pointer-events: none;
    opacity: 0;
}
.filterCloser.active{
    pointer-events: unset;
    cursor: pointer;
    opacity: .6;
}
.searchControllDiv{
    justify-content: space-between;
    align-items: center;
}
.toggleView{
    width: 34px;
    height: 20px;
    background-color: var(--blackClrOp1);
    border-radius: 20px;
    align-items: center;
    cursor: pointer;
    transition: background-color .4s;
}
.toggleView.active{
    background-color: var(--txtSecendaryClr);
}
.toggleView div{
    background-color: var(--bgClr);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 3px;
    transition: transform .4s;
}
.toggleView.active div{
    transform: translateX(-14px);
}
.filterMenu{
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 0;
    background-color: var(--bgClr);
    z-index: 986;
    transition: width .4s;
    grid-template-rows: 50px 1fr 55px;
    gap: 10px;
    overflow-x: hidden;
}
.filterMenu.active{
    width: 350px;
}
.filterMenuHeaderDiv{
    margin: 10px auto;
    width: 90%;
    position: relative;
}
.filterMenuHeaderDiv::after{
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--blackClrOp3);
    position: absolute;
    left: 0;
    bottom: -12px;
}
.filterMenuHeaderDiv h2{
    padding-right: 30px;
    background-image: url("/public/assets/filter.svg");
    background-position: right 5px center;
    background-repeat: no-repeat;
    white-space: nowrap;
}
.filterCloserBtn{
    background-image: url("/public/assets/close.svg");
    background-size: 18px;
}
.filterList{
    margin: 0 auto;
    width: 90%;
    overflow-y: auto;
    height: 100%;
    padding-left: 5px;
}

.filterList p{
    white-space: nowrap;
}
.confirmFilter{
    background-color: var(--txtSecendaryDarkerClr);
    color: var(--bgClr);
    border-radius: 5px;
    height: 40px;
    margin: 5px 10px 10px;
    white-space: nowrap;
}

.confirmFilter:hover{
    background-color: var(--txtSecendaryDarkerClr2);
    transition: background-color .6s;
}
/* filterOptionDiv */
.filterOptionDiv{
    background-color: var(--blackClrOp6);
    margin-top: 10px;
    gap: 5px;
    align-items: flex-start;
    padding: 7px;
    border-radius: 5px;
}
.filterOptionDiv button{
    padding: 3px 30px 3px 15px;
    background-color: transparent;
    border-radius: 5px;
    transition: background-color .6s;
    background-image: url("/public/assets/circle.svg");
    background-repeat: no-repeat;
    background-position: right 7px center;
    background-size: 15px;
}
.filterOptionDiv button.active{
    background-image: url("/public/assets/circleChecked.svg");
}

.filterOptionDiv button:hover{
    background-color: var(--blackClrOp4);
}

@media screen and (min-width:769px) {
    .searchSection{
        grid-template-columns: 1fr 2.2fr;
        gap: 10px;
    }
    .filterSection{
        position: sticky;
        top: 60px;
        height: 80vh;
        grid-template-rows: auto 1fr;
        background-color: var(--blackClrOp6);
        padding: 15px;
        border-radius: 7px;
    }
    .filterMenu{
        position: unset;
        width: unset !important;
        grid-template-rows: 1fr auto;
        border-top: 1px solid var(--blackClrOp2);
        height: unset !important;
        margin-top: 15px;
        padding-top: 15px;
        background-color: unset;
    }
    .filterMenu.active{
        width: unset;
    }
    .filterList{
        width: 100%;
        padding-left: 10px;
    }
    .filterCloser,.filterMenuHeaderDiv,.showFilterMenuBtn{
        display: none;
    }
    .toggleExistBtnDiv{
        justify-content: space-between;
        width: 100%;
    }
    .confirmFilter{
        margin: 10px 0 0;
    }
}
@media screen and (min-width:968px) {
    .searchSection{
        grid-template-columns: 1fr 3.2fr;
        gap: 10px;
    }
    .searchGridList{
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        margin-top: 20px;
    }
}
@media screen and (min-width:1100px) {
    .searchSection{
        grid-template-columns: 1fr 3.5fr;
    }
}
/* searchSection */
.blogSearchSection .filterMenu{
    border-top: none;
    margin-top: 0;
    padding-top: 0;
}