footer{
    border-top: 1px solid var(--blackClrOp2);
    padding-bottom: 30px;
}
.goToTopBtn{
    border: 1px dashed var(--txt9Clr);
    width: fit-content;
    align-self: flex-end;
    border-radius: 7px;
    padding: 5px 12px 5px 35px;
    background-image: url("/public/assets/arrowTop.svg");
    background-repeat: no-repeat;
    background-position: left 10px center;
    background-size: 14px;
    background-color: transparent;
    transition: background-color .6s;
}
.goToTopBtn:hover{
    background-color: var(--blackClrOp5);
}
/* customersGuid */
.customersGuid{
    grid-template-columns: 1fr 1fr;
}
.seperator{
    height: 1px ;
    border: none;
    background-color: var(--blackClrOp2) ;
    opacity: 1;
}
.customersGuid a{
    background-color: transparent;
    padding: 5px 35px 5px 15px;
    background-position: right 9px center;
    border-radius: 5px;
    transition: background-color .6s ,border .6s;
    border: 1px dashed transparent;
}
.customersGuid a:hover{
    background-color: var(--blackClrOp5);
    border: 1px dashed var(--blackClrOp1);
}
.callLink
{
    border-radius: 5px;
    transition: background-color .6s ,border .6s;
    border: 1px dashed transparent;
    padding: 3px 35px 3px 15px;
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-size: 18px;
    width: fit-content;
}
.callLink:hover{
    background-color: var(--blackClrOp5);
    border: 1px dashed var(--blackClrOp1);
}
.address{
    background-image: url("/public/assets/location.svg");
}
.phone{
    background-image: url("/public/assets/call.svg");
}
.email{
    background-image: url("/public/assets/email.svg");
}
/* socialLinksDiv */
.socialLink{
    height: 35px;
    width: 35px;
    background-color: var(--blackClrOp6);
    border-radius: 7px;
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color .6s , border .6s;
}
.socialLink:hover{
    background-color: var(--blackClrOp5);
    border: 1px dashed var(--blackClrOp2);
}
.insta{
    background-image: url("/public/assets/instagram.svg");
}
.telegram{
    background-image: url("/public/assets/telegram.svg");
}
.whatsapp{
    background-image: url("/public/assets/whatsapp.svg");
}
@media screen and (min-width:769px) {
    .footerContent{
        grid-template-columns: 1fr 0 1fr 0 1fr;
        align-items: flex-start;
        margin: 20px auto 0;

    }
    .seperator{
        height: 100%;
        width: 1px;
    }
    .customersGuid{
        grid-template-columns: 1fr;
    }
    .linkItem{
        width: fit-content;
    }
}