.modalContainer{
    background-color: var(--bgClr);
    border-radius: 7px;
    position: fixed;
    max-width: 300px !important;
    left: 20px;
    bottom: 20px;
    box-shadow: 0 0 5px var(--blackClrOp1);
    border: 1px solid var(--blackClrOp2);
    display: none;
    animation: toRight 4s;
    opacity: 0;
    z-index: 1000;
}
.closeMsg{
    width: 30px;
    height: 30px;
    align-self: flex-end;
    background-image: url("/public/assets/close.svg");
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 7px 0 0 7px;
}
.msgText{
    padding: 10px 0 10px;
    border-top: 1px solid var(--blackClrOp4);
    margin: 5px 15px 5px ;
    position: relative;
}
.msgText::after{
    width: 35px;
    height: 35px;
    background-color: var(--bgClr);
    border-radius: 50%;
    border: 1px solid var(--blackClrOp3);
    position: absolute;
    right: 0;
    top: -60px;
    content: "";
    background-image: url("/public/assets/message.svg");
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 0 5px var(--blackClrOp3);
    border: 1px solid var(--blackClrOp1);
}
@keyframes toRight {
    0%{transform: translateX(-200%); opacity: 0;}
    15%{transform: translateX(0); opacity: 1;}
    85%{transform: translateX(0); opacity: 1;}
    100%{transform: translateX(-200%); opacity: 0;}
}