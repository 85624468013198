.blueFillLoader{
    background-color: var(--txtSecendaryDarkerClr);
    border-radius: 7px;
    height: 45px;
}
.blueFillLoader div{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #ffffff27;
    border-right: 2px solid #ffffff27;
    animation: rotate 1s linear infinite;
}