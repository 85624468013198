.productItem{
    background-color: var(--bgClr);
    padding: 10px;
    border-radius: 7px;
    position: relative;
}
.productItemImage{
    width: 160px;
    border-radius: 7px;
    pointer-events: none;
    margin-bottom: 5px;
}
.priceDiv{
    position: relative;
    margin-top: 10px;
}
.discountText{
    text-align: center;
    padding-right: 5px;
    color: var(--bgClr);
    position: absolute;
    right: 0;
    top: 0;
    padding: 1px 26px 1px 10px;
    background-color: var(--redClr);
    border-radius: 15px;
    background-image: url("/public/assets/percent.svg");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 16px;
    opacity: .94;
}
.dashedPrice{
    width: fit-content;
    text-decoration: line-through;
    text-decoration-color: var(--tomatoOp1Clr);
}
.finalPrice{
    width: fit-content;
    margin-bottom: 2px;
}
.priceText{
    width: fit-content;
    margin: 17px 0 5px;
}
.productNotExist{
    color: var(--redClr);
    text-align: center;
    margin: 17px 0 5px;
    position: relative;
    opacity: .85;
}
.productNotExist::after,.productNotExist::before{
    content: "";
    background-color: var(--redClr);
    width: 23%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    opacity: .6;
    transform: translateY(-50%);
}
.productNotExist::before{
    left: unset;
    right: 0;
}
.seeProduct{
    text-align: center;
    padding: 5px 0;
    background-color: var(--txtSecendaryDarkerClr);
    border-radius: 7px;
    color: var(--bgClr);
    margin-top: 10px;
    transition: background-color .6s;
}
.seeProduct:hover{
    background-color: var(--txtSecendaryDarkerClr2);
}