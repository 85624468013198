main{
    margin-bottom: 40px;
}
.mobileLinks{
    margin-top: 55px;
}
/* imgLinkSection */
.imgLinkSection{
    display: -ms-grid;
    display: -moz-grid;
    display: grid;
    grid-template-columns: 1fr;
    gap: 18px;
    margin-top: 35px;
}
.imgLinkItemDiv{
    aspect-ratio: 2/.8;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--blackClrOp5);
    border: 1px solid var(--blackClrOp4);
    position: relative;
    flex-direction: column;
}
.imgLinkItemDiv:hover .imgLinkImg{
    transform:scale(1.04,1.04);
}
.imgLinkImg{
    aspect-ratio: 2/.8;
    transition: transform .6s;
}
.linkBgSpan{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    background-image: linear-gradient(to top , #111 ,#111111c9, #11111100);
}
.linkTitle{
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: var(--bgClr);
}
.linkTarget{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
/* gridItemList */
.gridItemList{
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 20px;
}
.gridItemList .productItem{
    padding: 10px;
    background-color: var(--blackClrOp8);
}
.gridItemList .productItemImage{
    width: 100%;
}
.toggleMenuProductDiv{
    gap: 10px;
}
.toggleMenuProductBtn{
    padding: 3px 10px;
    border: 1px dashed transparent;
    transition: background-color .6s , border .6s;
    border-radius: 7px;
}
.toggleMenuProductBtn.active{
    border: 1px dashed var(--blackClrOp1);
    background-color: var(--blackClrOp5) !important;
}
.toggleMenuProductBtn:hover{
    border: 1px dashed var(--blackClrOp2);
    background-color: var(--blackClrOp6);
}
.moreList{
    width: fit-content;
    padding: 4px 18px 4px 30px ;
    background-image: url("/public/assets/leftArrowBlue.svg");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: left 8px center;
    align-items: center;
    background-color: transparent;
    color: var(--txtSecendaryClr);
}
.blogTitle{
    padding-right: 30px;
    background-image: url("/public/assets/blog.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 18px;
}
@media screen and (min-width:769px) {
    .imgLinkSection{
        grid-template-columns: 49% 49%;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    .gridItemList{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .homeBlogGrid{
        grid-template-columns: 1fr 1fr;
    }
    
}
@media screen and (min-width:860px) {
    .homeBlogGrid{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (min-width:968px) {
    .gridItemList{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .productItem:nth-child(11),.productItem:nth-child(12){
        display: none;
    }
}