.manageNumberSection{
    margin: 20px 0 0;
    width: 100%;
    max-width: 280px;
    align-self: flex-end;
}
.manageNumberSection .manageItemDiv{
    grid-template-columns: auto 1fr auto;
    border-radius: 7px;
    background-color: var(--blackClrOp5);
    border: 1px solid var(--blackClrOp1);
    height: 33px;
    padding: 0 5px;
}
.manageNumberSection button{
    height: 30px;
    width: 30px;
}
.manageNumberSection > .increaseBtn{
    background-size: 18px;
}
.manageNumberSection > .decreaseBtn{
    background-size: 13.5px;
}
.manageNumberLoadingBtn{
    height: 30px;
    width: 30px;
}
.manageNumberLoadingBtn span{
    width: 20px;
    height: 20px;
    border-top: 2px solid var(--tomatoClr);
    border-bottom: 2px solid var(--tomatoClr);
    border-left: 2px solid var(--tomatoOp2Clr);
    border-right: 2px solid var(--tomatoOp2Clr);
    border-radius: 50%;
    animation: rotate 2s linear infinite;
}