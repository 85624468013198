.imgSection {
    position: relative;
}

.mainImage {
    border-radius: 7px;
    display: none;
}

.mobileImgsList {
    overflow-x: auto;
    border-radius: 7px;
    position: relative;
    touch-action: none;
}

.minImgsList {
    overflow-x: scroll;
    margin-top: 10px;
    height: 100px;
    gap: 10px;
    display: none;
}

.minImgItem {
    aspect-ratio: 1/1;
    height: 80px;
    width: 80px;
    border-radius: 7px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: border .6s;
}

.minImgItem:hover {
    border: 2px solid var(--tomatoOp2Clr);
}

.activeMinImgItem {
    border: 2px solid var(--tomatoClr) !important;
}

.nextBtn,
.preBtn {
    position: absolute;
    right: 20px;
    bottom: 0;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
    background-color: var(--bgClr);
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center right 46%;
    border-radius: 7px;
    box-shadow: 0 0 4px var(--txt9Clr);
    border: 1px solid var(--blackClrOp4);
    opacity: .9;
    transition: opacity .6s;
    background-image: url("/public/assets/rightArrow.svg");
}

.preBtn {
    right: 68px;
    background-position: center left 46%;
    opacity: .2;
    background-image: url("/public/assets/leftArrow.svg");
}

/* infoSection */
.infoSection {
    gap: 20px;
}

.shortDetailsList {
    margin-right: 20px;
}

.shortDetailsItem>.itemName {
    padding-right: 20px;
    position: relative;
}

.shortDetailsItem>.itemName::after{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--blackClrOp1);
    content: "";
    border-radius: 50%;
    height: 6px;
    width: 6px;
}

.detailsHeader,.detailsTitle {
    padding-right: 40px;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: right 7px top 85%;
    background-image: url("/public/assets/details.svg");
}
/* detailsList */
.detailsList{
    border: 1px solid var(--blackClrOp3);
    border-radius: 7px;
    margin-top: 20px;
}
.detailsItem{
    grid-template-columns: .4fr 1fr;
    padding: 7px 9px;
    gap: 10px;
    border-bottom: 1px solid var(--blackClrOp1);
    position: relative;
}
.detailsItem:last-child{
    border-bottom: none;
}
.detailsItem:nth-child(odd){
    background-color: var(--blackClrOp6);
}
.detailsList .seperator{
    height: 100%;
    width: 1px;
    background-color: var(--blackClrOp3);
    position: absolute;
    right: 28.5%;
}
/* propLabelDiv */
.propLabelDiv {
    position: relative;
    padding: 0 20px ;
}
.prop2LabelDiv{
    margin-bottom: 20px;
}
.propList {
    padding: 0 20px;
    flex-wrap: wrap;
}

.prop {
    background-color: var(--blackClrOp4);
    border-radius: 5px;
    cursor: pointer;
    padding: 3px 12px;
    white-space: nowrap;
}

.activeProp {
    background-color: dodgerblue;
    color: var(--bgClr);
}

.productPriceDiv {
    border-top: 1px solid var(--blackClrOp2);
    position: fixed;
    bottom: 0;
    background-color: var(--bgClr);
    z-index: 892;
    width: 100%;
    left: 0;
    box-sizing: border-box;
    padding: 20px 3% 10px;
    display: -ms-grid;
    display: -moz-grid;
    display: grid;
    grid-template-columns: 57% 36%;
    justify-content: space-between;
    align-items: center;
}

.discountDiv {
    justify-content: space-between;
}

.discountPercent {
    padding: 1px 25px 1px 8px;
    background-color: var(--redClr);
    border-radius: 7px;
    color: var(--bgClr);
    background-image: url("/public/assets/percent.svg");
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: right 6px center;
}

.discountPrice {
    text-decoration: line-through;
    text-decoration-color: var(--redClr);
}

.finalDiscountPrice {
    align-self: flex-end;
}

.addToCartBtn {
    background-color: var(--txtSecendaryDarkerClr);
    border-radius: 7px;
    height: 40px;
    color: var(--bgClr);
    transition: background-color .6s;
}

.addToCartBtn:hover {
    background-color: var(--txtSecendaryDarkerClr2);
}

.addToCartBtn span {
    padding-right: 32px;
    background-image: url("/public/assets/whiteCart.svg");
    background-repeat: no-repeat;
    background-position: right 0 center;
    background-size: 19px;
}
.pricePropInfo{
    display: none;
}
.notProductExist {
    background-color: var(--redClr);
    border-radius: 7px;
    height: 40px;
    color: var(--bgClr);
}

.productPriceDiv .manageItemDiv {
    grid-template-columns: auto 1fr auto;
    border-radius: 7px;
    background-color: var(--blackClrOp5);
    border: 1px solid var(--blackClrOp1);
    height: 45px;
    padding: 0 5px;
}

.increaseBtn,
.decreaseBtn {
    width: 35px;
    height: 35px;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.increaseBtn {
    background-image: url("/public/assets/redPlus.svg");
}

.deactiveIncreaseBtn {
    opacity: .4;
    cursor: default;
}

.decreaseBtn {
    background-image: url("/public/assets/redMinus.svg");
}

.trashBtn {
    background-size: 16px;
    background-image: url("/public/assets/redTrash.svg");
}

/* description */
.discriptionHeader {
    padding-right: 29px;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: right 0 center;
    background-image: url("/public/assets/text.svg");
}
.descriptionText {
    padding: 0 5px;
}
/* recmendedTitle */
.recmendedTitle{
    padding-right: 35px;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-image: url("/public/assets/hot.svg");
}
.recomendedList{
    width: 100%;
    overflow-x: auto;
    margin-top: 10px;
}
/* comments */
.commentTitle{
    padding-right: 38px;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-image: url("/public/assets/message.svg");
}
.noCommentHere{
    margin-top: 20px;
    border-radius: 7px;
    background-color: var(--blackClrOp6);
    padding: 40px 20px;
    text-align: center;
    border: 1px dashed var(--blackClrOp1);
    align-items: center;
    gap: 15px;
}
.commentTitle{
    margin-top: 20px;
}
.infoComment,.signToInsertComment .info{
    padding-right: 28px;
    background-image: url("/public/assets/info.svg");
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: right 2px center;
}
.signToInsertComment{
    border-radius: 7px;
    background-color: var(--blackClrOp6);
    padding: 0 20px 10px;
    text-align: center;
    border: 1px dashed var(--blackClrOp1);
    align-items: center;
    gap: 15px;
    background-image: url("/public/assets/transparentQuote.svg");
    background-size: 80px;
    background-repeat: no-repeat;
    background-position: left 5px bottom 5px;
    height: 200px;
}
.signToInsertComment .goToSign{
    color: var(--txtSecendaryDarkerClr);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    background-image: url("/public/assets/leftArrowBlue.svg");
    padding-left: 25px;
}
.submitComment{
    background-color: var(--txtSecendaryDarkerClr);
    color: var(--bgClr);
    margin-top: 20px;
    width: 100px;
    height: 35px;
    padding-right: 20px;
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: right 10px center;
    align-self: flex-end;
    border-radius: 7px;
    background-image: url("/public/assets/whiteReply.svg");
    transition: background-color .6s;
}
.submitComment:hover{
    background-color: var(--txtSecendaryDarkerClr2);
}
.insertCmLoader{
    background-color: var(--txtSecendaryDarkerClr);
    margin-top: 20px;
    width: 100px;
    height: 35px;
    align-self: flex-end;
    border-radius: 7px;
    position: relative;
    display: none;
}
.insertCmLoader::after{
    content: "";
    position: absolute;
    left: 38%;
    top: 17%;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border-top: 2px solid var(--bgClr);
    border-left: 2px solid var(--txtSecendaryClr);
    border-bottom: 2px solid var(--bgClr);
    border-right: 2px solid var(--txtSecendaryClr);
    animation: rotate 1s linear infinite ;
}
/* commentIrem */
.commentListItem{
    border-radius: 7px;
    background-color: var(--blackClrOp6);
    padding: 15px;
}
.userAvatar{
    width: 36px ;
    height: 36px ;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-image: url("/public/assets/user.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
    color: transparent;
    border: 1px dashed var(--blackClrOp2);
}
.cmText{
    padding: 0 10px;
}
.answerDiv{
    margin-top: 15px;
    padding: 15px;
    grid-template-columns: 20px 1px 1fr;
    background-color: var(--txtSecendaryClrOp4);
    gap: 20px;
    align-items: center;
    border-radius: 7px;
    border: 1px dashed var(--txtSecendaryClrOp1);
}
.answerLogo{
    width: 20px;
    height: 20px;
    background-image: url("/public/assets/verify.svg");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 3px;
    opacity: .9;
}
.verticalSeperator{
    height: 100%;
    width: 1px ;
    background-color: var(--blackClrOp2);
}
.loadMoreBtn{
    background-color: var(--txtSecendaryDarkerClr);
    border-radius: 7px;
    color: var(--bgClr);
    padding:  0 28px 0 5px;
    margin-top: 20px;
    width: fit-content;
    background-image: url("/public/assets/whitePlus.svg");
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: right 10px center;
    transition: background-color .6s;
    width: 120px;
    height: 35px;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
}
.loadMoreBtn:hover{
    background-color: var(--txtSecendaryDarkerClr2);
}
.loadingElem{
    width: 120px;
    background-color: var(--txtSecendaryDarkerClr);
    border-radius: 7px;
    height: 35px;
    margin-top: 20px;
    display: none;
    align-self: flex-end;
}
.loadingSpan{
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border-top: 2px solid var(--bgClr);
    border-left: 2px solid var(--txtSecendaryClr);
    border-bottom: 2px solid var(--bgClr);
    border-right: 2px solid var(--txtSecendaryClr);
    animation: rotate 1s linear infinite ;
}
.insertCommentTextArea{
    margin-top: 20px;
    outline: none;
    border: 1px solid var(--blackClrOp4);
    background-color: var(--blackClrOp6);
    border-radius: 7px;
    padding: 10px;
    resize: none;
    height: 140px;
    position: relative;
    background-image: url("/public/assets/transparentQuote.svg");
    background-size: 80px;
    background-repeat: no-repeat;
    background-position: left 5px bottom 5px;
}
/* linkDiv */
.linkDiv{
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 15px;
}
.shareBtn{
    background-image: url("/public/assets/share.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15.5px;
    height: 35px;
    width: 35px;
}

@media screen and (min-width:769px) {
    .nextBtn,
    .preBtn,
    .mobileImgsList {
        display: none;
    }
    .minImgsList {
        display: -webkit-flex;
        display: flex;
        margin-top: 15px;
    }
    .mainImage {
        display: unset;
    }
    .infoSection {
        grid-template-columns: 34% 33% 26%;
        justify-content: space-between;
        margin-top: 15px;
    }
    .productPriceDiv{
        position: unset;
        background-color: var(--blackClrOp6);
        border-radius: 7px;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        height: fit-content;
        align-items: unset;
        gap: 15px;
        padding: 15px;
        border: 1px solid var(--blackClrOp3);
    }
    .manageItemDiv,.manageCartDiv{
        order: 3;
    }
    .pricePropInfo{
        display: unset;
        order: 1;
    }
    .priceElem{
        order: 2;
    }
    .commentSection{
        grid-template-columns: 2fr 1fr;
        gap: 10px;
    }
    .insertCommentDiv{
        position: sticky;
        top: 50px;
        height: fit-content;
    }
    .insertCommentTextArea{
        height: 180px;
    }
}
@media screen and (min-width:968px) {
    .commentSection{
        gap: 20px;
    }
}
