.loadingContainer {
    width: 100%;
    height: 390px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    margin-top: 20px;
}

.loadingDiv {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    margin: 10px auto;
    gap: 4px;
}

.load {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--blackClrOp2);
    transform: translateY(5px);
}

.load1 {
    animation: jump .8s linear 0s infinite alternate;
}

.load2 {
    animation: jump .8s linear .3s infinite alternate;
}

.load3 {
    animation: jump .8s linear .6s infinite alternate;
}

@keyframes jump {
    0% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(-10px);
    }
}