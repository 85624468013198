.paginationSection{
    margin: 20px auto 0;
}
.pageHandlerBtn{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: var(--blackClrOp5);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color .4s ,background-image .4s;
}
.prePage{
    background-image: url("/public/assets/leftArrow.svg");
}
.prePage:hover{
    background-image: url("/public/assets/leftArrowWhite.svg");
}
.nextPage{
    background-image: url("/public/assets/rightArrow.svg");
}
.nextPage:hover{
    background-image: url("/public/assets/rightArrowWhite.svg");
}
.pageHandlerBtn:hover{
    background-color: var(--txtSecendaryDarkerClr);
}

.numbersDiv{
    background-color: var(--blackClrOp5);
    border-radius: 20px;
    padding: 0 5px;
}
.numberBtn{
    width: 36px ;
    height: 36px;
    transition: background-color .4s , color .4s;
    border-radius: 7px;
}
.numberBtn:hover{
    color: var(--bgClr);
    background-color: var(--txtSecendaryDarkerClr);
}
.seperatorText{
    width: 36px ;
    height: 36px;
}
.currentBtn{
    position: relative;
}
.currentBtn span{
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--txtSecendaryDarkerClr);
    color: #fff;
    border-radius: 5px;
    width: 36.5px;
    height: 36.5px;
    box-shadow: 0 0 7px var(--txtPrimaryClr);
    font-size: 1.2em;
}