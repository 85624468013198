.trackingFlexContainer{
    max-width: 550px;
    margin: 0 auto;
}
.trackingTitle{
    height: 40px;
    background-color: var(--txtSecendaryClrOp4);
    border-radius: 7px;
    border: 1px dashed var(--txtSecendaryClrOp1);
    color: var(--txtSecendaryDarkerClr);
    margin: 15px auto 0;
    width: 100%;
}
.trackingTitle span{
    padding-right: 38px ;
    background-image: url("/public/assets/shippingBlue.svg");
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: right center;
}
.tcInputDiv{
    margin-top: 20px;
}
.submitTc{
    background-color:var(--txtSecendaryClr);
    width: fit-content;
    padding: 5px 17px 5px 30px ;
    align-self: flex-end;
    border-radius: 5px;
    color: var(--bgClr);
    transition: background-color .6s;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: left 7px center;
    background-image: url("/public/assets/leftArrowWhite.svg");
}
.submitTc:hover{
    background-color: var(--txtSecendaryDarkerClr2);
}
/* leftSideDiv */
.leftSideDiv{
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid var(--blackClrOp3);
}
/* no Result */
.noResultDiv{
    text-align: center;
}
.noResultImg{
    height: 250px;
    background-image: url("/public/assets/noResult.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 240px;
}

/* result */
.resultTable{
    border-radius: 7px;
    border: 1px solid var(--blackClrOp2);
}
.resultTableItem{
    padding: 10px ;
    border-bottom: 1px solid var(--blackClrOp2);
}
.resultTableItem:nth-child(odd){
    background-color: var(--blackClrOp7);
}
@media screen and (min-width:769px) {
    .trackingGridContainer{
        grid-template-columns: 1fr 1fr;
    }
    .trackingGridContainer .leftSideDiv{
        padding-top: none;
        margin-top: none;
        padding-right: 40px;
        margin-right: 20px;
        border-top: none;
        border-right: 1px solid var(--blackClrOp3);
    }
    .trackingGridContainer .rightSideDiv{
        margin-left: 20px;
    }
    
}