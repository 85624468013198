@font-face {font-family:'Nazanin';src:url('/public/font/Nazanin.ttf')}
@font-face {font-family:'Koodak';src:url('/public/font/Koodak.ttf');}
@font-face {font-family:'Poppins';src:url('/public/font/Poppins.ttf');}
:root {
  --bgClr: #fbfcff;
  --lineBlack1Clr:#333;
  --lineBlack1ClrOp1:#111111ad;

  --blackClrOp1 : #0000003b;
  --blackClrOp2 : #221f1f2d;
  --blackClrOp3 : #00000023;
  --blackClrOp4 : #00000015;
  --blackClrOp5 : #0000000D;
  --blackClrOp6 : #00000007;
  --blackClrOp7 : #00000005;
  --blackClrOp8 : #00000009;
  --txtPrimaryClr:#222;
  --txtSecendaryClr:#3f8ccf;
  --txtSecendaryDarkerClr:#3377b3;
  --txtSecendaryDarkerClr2:#225a8a;
  --txtSecendaryClrOp1:#368bd665;
  --txtSecendaryClrOp2:#368bd62d;
  --txtSecendaryClrOp3:#368bd618;
  --txtSecendaryClrOp4:#368bd616;
  --tomatoClr:#ff836d;
  --tomatoOp1Clr:rgba(255, 99, 71, 0.582);
  --tomatoOp2Clr:rgba(255, 99, 71, 0.329);
  --tomatoOp3Clr:rgba(255, 99, 71, 0.123);
  --tomatoOp4Clr:rgba(255, 99, 71, 0.09);
  --redClr :#e74242;
  --greenClr:#2d812d;
  --greenClr2:#49b849;
  --greenClrOp1:#2d812d5b;
  --greenClrOp2:#2d812d28;
  --greenClrOp3:#17921711;
  --txt1Clr:#111;
  --txt3Clr:#333;
  --txt5Clr:#555;
  --txt7Clr:#777;
  --txt9Clr:#999;
  --txtAClr:#aaa;
  --txtEClr:#eee;
  --txtCClr:#ccc;
  --whiteClr:#fff;
  --whiteClr2:#ccc;
  --lightGrey : rgb(243, 243, 243);
  --lightGrey2 : rgb(223, 223, 223);
  --greyClr1:#dfdfdf;
  --greyClr2:#f6f6f6;
}
body,
html {
    margin: 0;
    padding: 0;
    background-color: var(--bgClr);
    scroll-behavior: smooth;
    min-width: 300px;
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

body::-webkit-scrollbar-thumb {
  background: #bbb; 
}

body::-webkit-scrollbar-thumb:hover {
  background: #666; 
}
a{
    text-decoration: none !important;
}
img{
    margin: 0;
}
button{
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: unset;
}
*{
    padding: 0;
    margin: 0;
    -webkit-user-select: none;
    -ms-user-select: none; 
    user-select: none; 
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}
hr{
  opacity: .4;
}
/* ---------------------- fonts */
.faFont{
  font-family: "Koodak",Arial, Helvetica, sans-serif !important;
  font-weight: 500 !important;
}
.enFont{
  font-family: "Poppins",Arial, Helvetica, sans-serif !important;
}
.titrFont{
  font-family: "Nazanin",Arial, Helvetica, sans-serif !important;
  font-weight: 900 !important;
}
.textSize1{
  font-size: 1.4em !important;
}
.textSize2{
  font-size: 1.2em !important;
}
.textSize3{
  font-size: 1.05em !important;
}
.textSize4{
  font-size: .98em !important;
}
.textSize5{
  font-size: .92em !important;
}
.textSize6{
  font-size: .85em !important;
}
.textDarkViolet{
  color: var(--darkViolet1) !important;
}
.textDarkViole2{
  color: var(--darkViolet2) !important;
}
.textGreenClr1{
  color: var(--greenClr);
}
.textLightViolet{
  color: var(--lightViolet1) !important;
}
.hideText{
  font-size: 0;
  color: transparent;
}
.justifyText{
  text-align: justify;
}
.textClr1{
  color: var(--txt1Clr);
}
.textClr3{
  color: var(--txt3Clr);
}
.textClr5{
  color: var(--txt5Clr);
}
.textClr7{
  color: var(--txt7Clr);
}
.textClr9{
  color: var(--txt9Clr);
}
.textClrA{
  color: var(--txtAClr);
}
.whiteClr{
  color: var(--whiteClr);
}
.whiteClr2{
  color: var(--whiteClr2);
}
.copyableText {
  -webkit-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
.seeMore{
  color: var(--txtSecendaryDarkerClr);
  background-image: url("/public/assets/leftArrowBlue.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: left 5px center;
  padding-left: 30px;
  transition: background-position .6s;
}
.seeMore:hover{
  background-position: left 10px center;
}
/* display */
.flex{
  display: -webkit-flex;
  display: flex;
}
.activeFlex{
  display: -webkit-flex !important;
  display: flex !important;
}
.flexCol{
  flex-direction: column;
}
.flexCenter{
  align-items: center;
  justify-content: center;
}
.justifyBetween{
  justify-content: space-between;
  align-items: center;
}
.alignCenter{
  align-items: center;
}
.grid{
  display: -ms-grid;
  display: -moz-grid;
  display: grid;
}
.gap5{
  gap: 5px;
}
.gap10{
  gap: 10px;
}
.gap15{
  gap: 15px;
}
.gap20{
  gap: 20px;
}
.gap30{
  gap: 30px;
}
.mar5{
  margin-top: 5px;
}
.mar10{
  margin-top: 10px;
}
.mar15{
  margin-top: 15px;
}
.mar20{
  margin-top: 20px;
}
.hide{
  display: none;
}
/* container */
.container{
  width: 92%;
  margin-right: auto;
  margin-left: auto;
}
/* style */
.btn{
  width: 35px;
  height: 35px;
  border-radius: 7px;
  background-color: var(--blackClrOp6);
  transition: background-color .6s;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
}
.btn::after{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: linear-gradient(to right , transparent ,var(--blackClrOp3));
  animation: click .6s ;
  display: none;
  left: 0;
  top: 0;
}
.btn:hover::after{
  display: block;
  opacity: 0;
  
}
.btn:hover{
  background-color: var(--txtSecendaryClrOp3);
}
.fitImage{
  width: 100%;
  aspect-ratio: 1/1;
  -o-object-position: center;
  object-position: center;
  -o-object-fit: cover;
  object-fit: cover;
}
.rtl{
  direction: rtl;
}
.ltr{
  direction: ltr;
}
.submitBtn{
  border-radius: 7px;
  background-color: var(--txtSecendaryDarkerClr);
  color: var(--bgClr);
  height: 40px;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:background-color .6s ;
}
.submitBtn:hover{
  background-color: var(--txtSecendaryDarkerClr2);
}
.customTextArea{
  outline: none;
  border: 1px solid var(--violetOp2);
  background-color: var(--blackClrOp6);
  height: 180px;
  border-radius: 7px;
  resize: none;
}
.customInput{
  outline: none;
  border: 1px solid var(--violetOp3);
  background-color: var(--blackClrOp6);
  border-radius: 7px;
  padding: 0 15px;
  font-family: "Poppins";
}
.customInput:placeholder-shown{
  direction: rtl;
  font-family: "Koodak";
}
.linkItem{
  padding: 3px 33px 3px 0;
  background-image: url("/public/assets/link.svg");
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 17px;
}
.customHr{
  width: 100%;
  margin: unset auto;
  opacity: .3;
}
/* scrollbar */
.hideScrollBar::-webkit-scrollbar{
  display: none;
}
.hideScrollBar{
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}
.customScrollBar::-webkit-scrollbar {
  width: 8px;
}
.customScrollBar::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.customScrollBar::-webkit-scrollbar-thumb {
  background: #ddd; 
}
.customScrollBar::-webkit-scrollbar-thumb:hover {
  background: #aaa; 
}
.iconText{
  background-color: var(--blackClrOp7);
  padding: 7px;
  border-radius: 7px;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  direction: rtl;
  gap: 10px;
  cursor: pointer;
  height: 35px;
}
.iconText:hover{
  background-color: var(--blackClrOp5);
}
.iconText p{
  display: none;
}
.iconText span{
  width: 20px;
  height: 20px;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.fitContent{
  width: fit-content;
}
@keyframes click {
  from{
    opacity: 0;
    transform: translateX(-100%);
  }
  to{
    opacity: 1;
    transform: translateX(100%);
  }
}
@keyframes fadein{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@keyframes rotate{
  from{
    transform: rotate(0);
  }
  to{
    transform: rotate(360deg);
  }
}
@media screen and (min-width:769px) {
  .iconText p{
    display: flex;
  }
}
@media screen and (min-width:968px) {
  .container{
    max-width: 1350px;
  }
}