.offersSection{
    margin-top: 30px;
}
.offerTitle{
    background-color: var(--tomatoOp3Clr);
    border-radius: 7px 7px 0 0;
    width: fit-content;
    padding: 0 15px;
    position: relative;
    height: 38px;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.offerTitle::after{
    background-color: var(--tomatoOp3Clr);
    height: 25px;
    position: absolute;
    left: -15px;
    bottom: 0;
    width: 15px;
    content: "";
    z-index: 50;
}
.offerTitle::before{
    background-color: var(--bgClr);
    height: 25px;
    position: absolute;
    left: -15px;
    bottom: 0;
    width: 15px;
    z-index: 51;
    content: "";
    border-radius: 0 0 7px 0;
}
.offersList{
    overflow-x: auto;
    background-color: var(--tomatoOp3Clr);
    border-radius: 7px 0 7px 7px;
    padding: 15px;
}
.seeAllOffers{
    width: fit-content;
    padding: 155px 50px 0 50px;
    background-image: url("/public/assets/link.svg");
    white-space: nowrap;
    background-repeat: no-repeat;
    background-position: top 100px center;
    background-size: 45px;
    border-radius: 7px;
    border: 1px dashed transparent;
    background-color: transparent;
    transition: border .4s , background-color .4s;
}
.seeAllOffers:hover{
    border: 1px dashed var(--blackClrOp2);
    background-color: var(--tomatoOp3Clr);
}