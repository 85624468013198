.errorContainer{
    height: 70vh;
    justify-content: center;
    margin-top: 10px;
}
.errorMsg{
    padding-top: 240px;
    background-size: 240px;
    background-position: center top ;
    background-repeat: no-repeat;
    background-image: url("/public/assets/noResult.png");
}
.tryAgainBtn{
    background-color: var(--blackClrOp6);
    border-radius: 7px;
    padding: 4px 38px 4px 14px;
    align-self: center;
    margin-top: 20px;
    border: 1px dashed var(--blackClrOp2);
    transition: background-color .6s;
    background-image: url("/public/assets/again.svg");
    background-position: right 12px center;
    background-repeat: no-repeat;
    background-size: 16px;
}
.tryAgainBtn:hover{
    background-color: var(--blackClrOp4);
}