.blogItem{
    display: none;
    animation: fadein .6s ;
}
.blogItemImage{
    aspect-ratio: 2/1;
    border-radius: 7px;
    width: 100%;
    max-height: 280px;
}
.activeBlogItem{
    display: -ms-grid;
    display: -moz-grid;
    display: grid;
}
.actionBtnDiv{
    gap: 10px;
}
.toggleDotList .dot{
    background-color: var(--blackClrOp6);
}
.toggleDotList .activeDot{
    background-color: var(--blackClrOp3);
}
/* noResultDiv */
.noResultTitle{
    padding: 300px 60px 0;
    max-width: 400px;
    margin: 0 auto;
    background-size: 280px;
    background-repeat: no-repeat;
    background-position: top 20px center;
    background-image: url("/public/assets/noResult.png");
}
.noResultLink{
    border-radius: 7px;
    border: 1px dashed var(--blackClrOp2);
    width: fit-content;
    padding: 5px 15px 5px 35px;
    background-color: var(--blackClrOp6);
    cursor: pointer;
    margin: 10px auto 20px;
    background-image: url("/public/assets/leftArrow.svg");
    background-repeat: no-repeat;
    background-position: left 10px center;
    background-size: 20px;
    transition: background-color .6s;
}
.noResultLink:hover{
    background-color: var(--blackClrOp5);
}
.blogPropertyDiv{
    justify-content : flex-end;
}
.blogPropertyDiv p{
    padding: 3px 12px 3px 30px;
    background-color: var(--blackClrOp5);
    border-radius: 7px;
    background-repeat: no-repeat;
    background-position: left 10px center;
    background-size: 15px;
    position: relative;
}
.blogPropertyDiv p.likeText{
    background-image: url("/public/assets/heart.svg");
    background-size: 16px;
}
.blogPropertyDiv p.commentText{
    background-image: url("/public/assets/comment.svg");
}
.blogPropertyDiv p.visitText{
    background-image: url("/public/assets/visit.svg");
    background-position: left 9px center;
}
.blogPropertyDiv p::after{
    position: absolute;
    left: 50%;
    top: -12px;
    width: 0;
    height: 0;
    content: "";
    border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid var(--txt3Clr);
    transform: translateX(-50%);
    display: none;
    animation: fadein .6s;
}
.blogInfoHoverText{
    position: absolute;
    top: calc( -135% - 2px );
    background-color: var(--blackClrOp1);
    border-radius: 7px;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    padding: 3px 10px;
    display: none;
    animation: fadein .6s;
    color: var(--bgClr);
    background-color: var(--txt3Clr);
}
.blogPropertyDiv p:hover::after,.blogPropertyDiv p:hover > .blogInfoHoverText{
    display: block;
}
@media screen and (min-width:769px){
    .blogItem{
        grid-template-columns: 1fr 1fr;
    }
    .blogItemImage{
        order: 2;
        border-radius: 5px;
    }
    .actionBtnDiv{
        gap: 5px;
    }
}
/* posts grid */
.postHeaderDiv{
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid var(--blackClrOp3);
}
.changeListBtn{
    padding: 3px 10px;
    border: 1px solid transparent;
    transition: background-color .6s , border .6s;
    border-radius: 7px;
}
.changeListBtn.active{
    border: 1px dashed var(--blackClrOp1);
    background-color: var(--blackClrOp5);
}
.changeListBtn:hover{
    border: 1px dashed var(--blackClrOp2);
    background-color: var(--blackClrOp6);
}
.blogGridItem{
    border-radius: 7px;
    background-color: var(--blackClrOp6);
    padding: 10px;
}
.gridInfoDiv{
    grid-template-columns: 1fr 2fr;
    gap: 15px;
}
.blogGridImg{
    aspect-ratio: 2/1.5;
    width: 100%;
    border-radius: 7px;
    align-self: center;
}
/* searchContainer */
.searchContainer{
    padding: 15px;
    background-color: var(--blackClrOp6);
    border-radius: 7px;
    margin-top: 25px;
    gap: 10px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}
.blogSearchInput{
    padding: 5px 35px 5px 15px;
    background-image: url("/public/assets/search.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    background-color: var(--blackClrOp5);
}
.submitSearch{
    background-color: var(--txtSecendaryDarkerClr);
    width: fit-content;
    padding: 4px 16px 4px 27px;
    border-radius: 5px;
    color: var(--bgClr);
    background-image: url("/public/assets/leftArrowWhite.svg");
    background-repeat: no-repeat;
    background-position: left 7px center;
    background-size: 16px;
    align-self: flex-end;
    transition: background-color .6s;
}
.submitSearch:hover{
    background-color: var(--txtSecendaryDarkerClr2);
}
@media screen and (min-width:768px){
    .blogGridList{
        grid-template-columns: 1fr 1fr ;
    }
    .gridInfoDiv{
        grid-template-columns: 1fr;
    }
    .blogGridImg{
        aspect-ratio: 2/1.28;
        max-height: 160px;
    }
    .blogItemActionDiv{
        margin-top: auto;
    }
    .blogsContent{
        grid-template-columns: 2.4fr 1fr;
        gap: 10px;
    }
    .searchContainer{
        margin-top: 10px;
        position: sticky;
        top: 65px;
        height: fit-content;
    }
}
@media screen and (min-width:968px){
    .blogsContent{
        grid-template-columns: 2.7fr 1fr;
        gap: 20px;
    }
}
@media screen and (min-width:1100px){
    .blogsContent{
        grid-template-columns: 2.8fr 1fr;
        gap: 20px;
    }
    .blogGridList{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .searchContainer{
        max-width: 280px;
        margin-right: auto;
    }
}