.blogItemCover{
    aspect-ratio: 2/1;
    border-radius: 7px;
    margin-top: 10px;
    max-height: 300px;
}
.blogItemLink{
    border-right: 4px solid var(--txtSecendaryDarkerClr);
    background-color: var(--txtSecendaryClrOp3);
    padding: 6px 35px 6px 15px;
    border-radius: 7px 0 0 7px;
    background-image: url("/public/assets/link.svg");
    background-position: right 9px center;
    background-repeat: no-repeat;
    background-size: 16px;
    position: relative;
    overflow: hidden;
    max-width: 440px;
}
.blogItemLink::after{
    width: 100%;
    height: 100%;
    background-color:var(--txtSecendaryClrOp3);
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    transition: width .4s;
}
.blogItemLink:hover::after{
    width: 100%;
}
.blogItemImg,.blogItemVideo{
    aspect-ratio: 2/1;
    border-radius: 7px;
}
.blogSection .customHr{
    margin-top: 15px;
}
.blogItemPropertyDiv p{
    padding: 3px 12px 3px 30px;
    background-color: var(--blackClrOp5);
    border-radius: 7px;
    background-repeat: no-repeat;
    background-position: left 10px center;
    background-size: 15px;
    position: relative;
}
.blogItemPropertyDiv p.likeText{
    background-image: url("/public/assets/heart.svg");
    background-size: 16px;
}
.blogItemPropertyDiv p.commentText{
    background-image: url("/public/assets/comment.svg");
}
.blogItemPropertyDiv p.visitText{
    background-image: url("/public/assets/visit.svg");
    background-position: left 9px center;
}
.blogItemPropertyDiv p::after{
    position: absolute;
    left: 50%;
    top: -12px;
    width: 0;
    height: 0;
    content: "";
    border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid var(--txt3Clr);
    transform: translateX(-50%);
    display: none;
    animation: fadein .6s;
}
.blogInfoHoverText{
    position: absolute;
    top: calc( -135% - 2px );
    background-color: var(--blackClrOp1);
    border-radius: 7px;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    padding: 3px 10px;
    display: none;
    animation: fadein .6s;
    color: var(--bgClr);
    background-color: var(--txt3Clr);
}
.blogItemPropertyDiv p:hover::after,.blogItemPropertyDiv p:hover > .blogInfoHoverText{
    display: block;
}
.blogItemDate{
    padding: 4px 34px 4px 10px;
    background-image: url("/public/assets/calendar.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 15px;
    background-color: var(--blackClrOp5);
    border-radius: 7px;
    opacity: .9;
}
/* tagsList */
.tagsList{
    flex-wrap: wrap;
    margin-top: 15px;
    font-size: .95em;
}
.tagItem{
    border-radius: 7px;
    background-color: var(--blackClrOp5);
    padding: 4.5px 25px 4.5px 10px;
    transition: background-color .6s;
    background-image: url("/public/assets/hashtag.svg");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 11px;
}
.tagItem:hover{
    background-color: var(--blackClrOp4);
}
/* styles */
.blogItemShareBtn,.blogItemLikeBtn{
    width: fit-content;
    background-color: var(--blackClrOp6);
    margin-top: 20px;
    padding: 5px 35px 5px 12px;
    background-image: url("/public/assets/share.svg");
    background-repeat: no-repeat;
    background-position: right 11px center;
    background-size: 12.8px;
    border-radius: 5px;
    transition: background-color .6s;
}
.blogItemLikeBtn{
    background-image: url("/public/assets/heart.svg");
    transition: background-image .6s , background-color .6s;
}
.blogItemShareBtn:hover,.blogItemLikeBtn:hover{
    background-color: var(--blackClrOp5);
}
.changingLikeBtn{
    position: relative;
    cursor: default;
}
.blogItemLikeBtn.liked{
    background-image: url("/public/assets/heart-fill.svg");
}
.changingLikeBtn::before{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bgClr);
    border-radius: 7px;
    opacity: .8;
}
.changingLikeBtn::after{
    position: absolute;
    content: "";
    left: 42%;
    top: 15%;
    width: 15px;
    height: 15px;
    border-radius: 7px;
    border-top: 3px solid var(--txt7Clr);
    border-bottom: 3px solid var(--txt7Clr);
    border-left: 3px solid var(--blackClrOp2);
    border-right: 3px solid var(--blackClrOp2);
    border-radius: 50%;
    animation: rotate linear infinite 1s;
}
/* comments */
.blogItemCommentSection .insertCmLoader{
    display: block !important;
}

/* latestPostList */
.latestPostList{
    gap: 10px;
}

.latestPostItem{
    background-color: var(--blackClrOp6);
    grid-template-columns: 70px auto;
    align-items: center;
    gap: 15px;
    padding: 10px;
    border-radius: 7px;
    cursor: pointer;
    border: 1px dashed transparent;
    transition: border .6s;
}
.latestPostItem:hover{
    border: 1px dashed var(--blackClrOp1);   
}
.latestLinkImg{
    border-radius: 5px;
}
.blogLeftSideDiv h2{
    padding: 5px 25px 5px 10px ;
    background-image: url("/public/assets/blog.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 15px;
}
@media screen and (min-width : 769px) {
    .blogContentGrid{
        grid-template-columns: 2fr 1fr;
        gap: 10px;
    }
    .linksHr{
        display: none;
    }
    .blogLeftSideDiv{
        margin-top: 15px;
    }
}
@media screen and (min-width : 968px) {
    .blogContentGrid{
        grid-template-columns: 2.7fr 1fr;
        gap: 40px;
    }
    .blogLeftSideDiv{
        max-width: 270px;
        margin-right: auto;
    }
}