@media screen and (min-width:769px){
    .aboutSection{
        grid-template-columns: 2fr 1fr;
        gap: 15px;
    }
    .lastAboutHr{
        display: none;
    }
    .aboutLeftSideDiv{
        max-width: 210px;
        width: 100%;
        margin: 50px auto 0 0;
    }
}
@media screen and (min-width:968px){
    .aboutSection{
        grid-template-columns: 2fr 1fr;
        gap: 30px;
    }
    .lastAboutHr{
        display: none;
    }
    .aboutLeftSideDiv{
        max-width: 280px;
    }
}