.userSection{
    margin-bottom: 40px;
}
.userHeader{
    grid-template-columns: 1fr auto;
    align-items: center;
}
.iconText .logout{
    background-image: url("/public/assets/logout.svg");
}
.menuOptionDiv{
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 15px;
}
.menuOptionDiv button{
    width: 100%;
    background-color: var(--blackClrOp6);
    border-radius: 7px;
    padding: 140px 0 30px;
    background-size: 70px;
    background-repeat: no-repeat;
    background-position: top 40px center;
    border: 1px dashed transparent;
    transition: background-color .6s,border .6s;

}
.menuOptionDiv button:hover{
    background-color: var(--blackClrOp5);
    border: 1px dashed var(--blackClrOp2);
}
.largeBtn.orders{
    background-image: url("/public/assets/history.svg");
}
.largeBtn.edit{
    background-image: url("/public/assets/user.svg");
}
.largeBtn.changePass{
    background-image: url("/public/assets/lock.svg");
}
.largeBtn.logOut{
    background-image: url("/public/assets/logout.svg");
}
/* userActionDiv */
.userActionDiv{
    height: 45px;
    overflow-x: auto;
    border-bottom: 1px solid var(--blackClrOp3);
    padding: 10px 0;
}
.userActionDiv > button{
    white-space: nowrap;
}
.activeMenuBtn{
    border: 1px dashed var(--blackClrOp1);
    border-radius: 7px ;
    background-color: var(--blackClrOp6);
    padding: 3px 10px;
}
/* orders */
.ordersTitle{
    text-align: center;
    background-color: var(--blackClrOp6);
    border: 1px dashed var(--blackClrOp3);
    border-radius: 5px;
    padding: 5px 0;
}
.orderItem{
    grid-template-columns: 100px 1fr auto;
    gap: 20px;
    padding: 10px;
    background-color: var(--blackClrOp6);
    border-radius: 7px;
}
.orderImg{
    border-radius: 5px;
}
.iconText .details{
    background-image: url("/public/assets/text.svg");
}
.orderItem .iconText{
    align-self: center;
}
/* editUser */
.loadBlueBtn{
    height: 40px;
    background-color: var(--txtSecendaryDarkerClr);
    border-radius: 7px;
    width: 100%;
}
.loadBlueBtn span{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #ffffff27;
    border-right: 2px solid #ffffff27;
    animation: rotate linear infinite 1s;
}
@media screen and (min-width:769px) {
    .menuOptionDiv{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .profileGridContent{
        grid-template-columns:  200px 3fr;
        gap: 20px;
    }
    .userActionDiv{
        flex-direction: column;
        height: fit-content;
        background-color: var(--blackClrOp7);
        border: 1px solid var(--blackClrOp2);
        position: sticky;
        top: 60px;
        border-radius: 5px;
        gap: 2px;
        padding: 5px;
    }
    .changeMenuBtn{
        width: 100%;
        padding: 7px 5px ;
        text-align: right;
        border-right: 4px solid transparent;
        border-radius: 0;
        background-color: transparent;
    }
    .activeMenuBtn{
        padding: 7px 5px ;
        border: none;
        border-right: 4px solid var(--redClr);
        background-color: var(--tomatoOp3Clr);
    }
}
@media screen and (min-width:968px) {
    .menuOptionDiv{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .profileGridContent{
        grid-template-columns:  260px 3fr;
        gap: 60px;
    }
    .editProfileSection,.editUserPassSection,.ordersSection{
        width: 70%;
        margin: 20px auto 0;
    }
}