.quesitonItemDiv{
    border-radius: 7px ;
    background-color: var(--blackClrOp6);
    padding: 15px;
    position: relative;
    border: 1px dashed transparent;
    transition: border .6s ,background-color .6s;
}
.quesitonItemDiv:hover{
    border: 1px dashed var(--blackClrOp2);
    background-color: var(--blackClrOp5);
}
.answer{
    display: none;
    text-align: justify;
    animation: fadein .6s;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid var(--blackClrOp2);
}
.questionLabel{
    cursor: pointer;
}
.questionInput{
    appearance: none;
    width: 25px;
    height: 25px;
    background-image: url("/public/assets/expand.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
    position: absolute;
    left: 17px;
    top: 14px;
    pointer-events: none;
    transition: transform .6s;
    opacity: .7;
}
.questionInput:checked{
    transform: rotateX(180deg);
}
.questionInput:checked + .answer{
    display: flex;
}
/* noResultText */
.noResultText{
    padding: 250px 0 50px 0;
    text-align: center;
    background-size: 240px;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url("/public/assets/noResult.png");
    margin-top: 45px;
}